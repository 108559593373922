import { template as template_cc204dd9d72944a89de9481eab603aab } from "@ember/template-compiler";
const WelcomeHeader = template_cc204dd9d72944a89de9481eab603aab(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
