import { template as template_61d68ffdbe3647a9bd40bd8d5921f50f } from "@ember/template-compiler";
const FKLabel = template_61d68ffdbe3647a9bd40bd8d5921f50f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
